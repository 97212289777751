.engPlastics {
    text-align: center;
    font-family: sans-serif;
}

.plasticImgContainer {
    display: flex;
    justify-content: center;
}

.plasticImg {
    width: 80%;
    height: auto;
}

.plasticContainer {
    width: 100%;
}