.gasketsContainer {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
}

.gasketsBtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
}

.imgBtn {
    border-radius: 7px;
    border: none;
    background-color: transparent;
    transition: 0.4s ease-in-out;
}

.imgBtn p {
    padding: 5px;
    border-radius: 7px;
    transition: 0.4s ease-in-out;
}

.imgBtn:hover p {
    background-color: gray;
    color: white;
}

.gasketsImg {
    height: 100px;
}

@media (max-width:770px){
    .gasketsImg {
        height: 60px;
        width: auto;

    }
}

