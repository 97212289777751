.blank {
    height: 105px;
    background-color: white;
    border: none;
}

.contactContainer {
    height: max-content;
    animation: fade 0.5s ease-in;
    padding-bottom: 10px;
    font-style: oblique;
    color: grey;
    font-size:small;
}


@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.contactHeader {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: auto auto auto 60px;
    padding-top: 10px;
    padding-bottom: 5px;
    width: max-content;
    border-bottom: 1px solid grey;
}

sup {
    animation: ringing 1s infinite;
}

@keyframes ringing {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.contactDetails {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 10px auto auto 60px;
}

.mailButton {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: large;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 10px auto auto 60px;
    padding: 0;
    color: gray;
}

@media (max-width:1030px) {

    .contactHeader {
        font-size: medium;
        padding-top: 5px;
    }

    .contactDetails,
    .mailButton {
        font-size: small;

    }


}

@media (max-width:770px) {

    .contactContainer {
        font-size: small;
    }

    .mailButton {
        font-size: small;
    }

}

@media(max-width:321px) {
    .contactDetails {
        font-size: x-small;
    }
}