.imlToolingContainer {
    text-align: center;
    margin: auto 20px auto 20px;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
}

.pdfButton {
    border: none;
    background-color: rgba(0, 0, 0, 0.700);
    color: white;
    padding: 5px 5px 5px 5px;
    margin: 10px 20px auto auto;
    font-family: Arial, Helvetica, sans-serif;
    outline: none;
    transition: 0.4s ease-in-out;
}

.pdfButton:hover {
    background-color: transparent;
    color: black;
}

@media (max-width:770px) {
    .toolingDesignImg {
        height: 200px;
        width: 100%;
    }
}
