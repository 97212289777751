.navbarContainer {
    height: max-content;
    width: 100%;
    position: fixed;
}

.right {
    float: right;
    margin-top: 25px;
}

.navbarLinks {
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 40px;
    color: black;
    padding: 7px;
    font-size: large;
    font-style: oblique;
    transition: 0.4s;
    border-radius: 7px;
}

.navbarLinks:hover {
    background-color: rgba(0, 0, 0, 0.500);
    color: white;
}

.navbarLogo {
    height: 60px;
    width: auto;
    padding: 8px 0px 0px 15px;
}

.header {
    margin-top: 3px;
    margin-left: 735px;
}

.headerLinks {
    transition: 0.5s;
    text-decoration: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.505);
    padding: 8px 16px 8px 16px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif
}

.headerLinks:hover {
    background: transparent;
    color: black;
}

@media only screen and (max-width:1025px) {

    .header {
        margin-left: 526px;
        margin-top: -10px;
    }

    .headerLinks {
        font-size: small;
        padding: 4px 8px 4px 8px;
    }

}

@media only screen and (max-width:770px) {

    .navbarLinks {
        font-size: small;
        margin-right: 15px;
    }

    .right {
        margin-top: 20px;
        margin-right: 10px;
    }

    .navbarLogo {
        height: 45px;
        width: auto;
        padding-top: 5px;
    }

    .header {
        margin-left: 440px;
        margin-top: 3px;
    }

    .headerLinks {
        font-size: x-small;
        padding: 4px 8px 4px 8px;
    }
}

@media (max-width:450px) {

    .navbarLinks {
        font-size: x-small;
        margin-right: 0px;
    }

    .right {
        margin-right: 22px;
        margin-top: 15px;
    }

    .navbarLogo {
        width: 135px;
        height: auto;
    }

    .header {
        margin-left: 62px;
        margin-top: 15px;
    }

    .headerLinks {
        font-size: x-small;
        padding: 4px 8px 4px 8px;
    }
}

@media (max-width:380px) {

    .navbarLogo {
        width: 120px;
        height: auto;
    }

    .navbarLinks {
        padding: 5px;
        border-radius: 3px;
        font-size: x-small;
    }

    .header {
        margin-left: 38px;
        margin-top: 15px;
    }

    .right {
        margin: 12px 22px auto auto;
    }

}

@media (max-width:321px) {

    .navbarLogo {
        width: 100px;
        height: auto;
        margin-left: -10px;
        margin-top: 5px;
    }

    .right {
        margin: 15px 20px auto auto;
    }

    .navbarLinks {
        padding: 4px;
        border-radius: 3px;
        font-size: xx-small;
    }

    .header {
        margin-left: 21px;
        margin-top: 15px;
    }

    .headerLinks{
        font-size: xx-small;
    }

}