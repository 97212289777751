.preloader {
    display: flex;
    justify-content: center;
    margin-top: 200px;
    height: 100px;
    animation: 2s loading infinite;
}

@keyframes loading {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width:500px){
    .loadingImg {
        height: 50px;
        width: auto;
    }
}