.aerospaceContainer {
    margin-top: auto 20px auto 20px;
    width: 100%;
}

.aeroDetails {
    font-family: Arial, Helvetica, sans-serif;
    font-size: larger;
    text-align: center;
    margin: 10px auto 10px auto;

}

.floatRight {
    float: right;
    margin: 60px 140px auto auto;
}

.floatRightDiv {
    margin: 20px auto auto auto;
    color: black;
}

.aeroDetailsP {
    text-align: center;
}

@media screen and (max-width:1025px) {
    .aeroImg {
        height: 260px;
    }
}

@media screen and (max-width:800px) {

    .floatRight {
        display: flex;
        margin: auto 25px auto 25px;
        font-size: small;
    }

    .aeroImg {
        height: 230px;
        width: auto;
        margin-left: 10%;
    }
}

@media (max-width:480px){
    .aeroImg{
        margin-left: 0;
        width: 100%;
    }
}