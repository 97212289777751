.flex {
    display: flex;
    margin-left: 365px;
}

.electricScooter {
    height: 380px;
    padding: 10px;
}

.scooterDisplay {
    height: 400px;
    width: 300px;
    overflow: hidden;
}

.varientsbtnContainer {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
}

.varientBtn {
    text-decoration: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.500);
    margin-top: 10px;
    padding: 5px 15px 5px 15px;
    border-radius: 7px;
    font-family: Arial, Helvetica, sans-serif;
}

@media (max-width:1025px) {
    .flex {
        margin-left: 250px;
    }

    .varientsbtnContainer {
        margin-top: 130px;
        margin-left: -20px;
    }
}

@media (max-width:800px) {
    .flex {
        margin-left: 170px;
    }
}

@media (max-width:430px) {
    .flex {
        margin-left: 50px;
        padding-top: 100px;
    }

    .electricScooter {
        height: 280px;
        padding: 10px;
    }

    .scooterDisplay {
        height: 300px;
        width: 300px;
        overflow: hidden;
    }

    .varientsbtnContainer {
        margin-top: 50px;
        font-size: xx-small;
        margin-right: 50px;
    }
}

@media (max-width:322px) {
    .flex {
        margin-left:15px;
        padding-top: 100px;
    }

    .varientsbtnContainer {
        margin-top: 50px;
        font-size: xx-small;
        margin-right: 25px;
    }

}