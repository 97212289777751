.footerContainer {
    float: none;
    background-color: white;
    height: max-content;
    width: 100%;
}

.footerLogoContainer {
    text-align: center;
}

.logoContainer {
    text-align: center;
}

.LogoInsta,
.LogoFb,
.LogoTweet,
.LogoWatsapp {
    height: 40px;
    width: 40px;
    margin: 0px 20px auto 20px;
    transition: 0.4s;
    border-radius: 50px;
}

.LogoFb:hover {
    background-color: rgb(49, 185, 249);
}

.LogoTweet:hover {
    background-color: rgb(49, 185, 249);
}

.LogoInsta:hover {
    background-color: violet;
}

.LogoWatsapp:hover {
    background-color: rgb(155, 249, 4);
}

.footerBar {
    display: flex;
    justify-content: center;
    height: 20px;
    margin-top: 10px;
}

.footerLinks {
    text-decoration: none;
    color: gray;
    font-family: sans-serif;
    font-size: small;
    transition: 0.4s;
    margin-right: 20px;
}

.footerLinks:hover {
    text-decoration: underline;

}

@media (max-width:1030px) {

    .LogoInsta,
    .LogoFb,
    .LogoTweet,
    .LogoWatsapp {
        height: 30px;
        width: 30px;
        margin: 5px 10px auto 10px;
        transition: 0.4s;
        border-radius: 50px;
    }

}

@media (max-width:450px) {
    .footerLinks {
        font-size: x-small;
    }

}