.home {
    animation: fade 0.5s ease-in;
    height: 100vh;
    overflow-y: hidden;
}

::-webkit-scrollbar {
    display: none;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.evSpeedBike {
    width: 100%;
    height: 100vh;
    margin-top: -30px;
}

.trailerBtn {
    position: sticky;
    top: 500px;
    left: 450px;
    background-color: rgba(0, 0, 0, 0.500);
    padding: 10px 30px 10px 30px;
    border: none;
    border-radius: 7px;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: none;
}

.knowmore {
    position: sticky;
    top: 500px;
    left: 650px;
    background-color: rgba(255, 255, 255, 0.500);
    padding: 10px 30px 10px 30px;
    border: none;
    border-radius: 7px;
    color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: none;
}

.videoIcn {
    width: 11px;
    height: 11px;
}


@media screen and (max-width:1030px) {

    .home {
        animation: fade 0.5s ease-in;
        height: 100vh;
        overflow-y: hidden;
    }


    .evSpeedBike {
        margin-top: -20px;
        width: 100%;
        height: 100vh;
    }

    .trailerBtn {
        position: sticky;
        top: 400px;
        left: 300px;
    }

    .knowmore {
        position: sticky;
        top: 400px;
        left: 500px;
    }

    .varientsbtnContainer {
        font-size: small;
    }

}

@media screen and (max-width:800px) {

    .home {
        animation: fade 0.5s ease-in;
        height: 100vh;
        overflow-y: hidden;
    }

    .evSpeedBike {
        width: 100%;
        height: 100vh;
    }

    .trailerBtn {
        position: sticky;
        top: 450px;
        left: 190px;
        font-size: small;
    }

    .knowmore {
        position: sticky;
        top: 450px;
        left: 370px;
        font-size: small;
    }

}

@media (max-width:450px) {
    .home {
        animation: fade 0.5s ease-in;
        height: 100vh;
        overflow-y: hidden;
    }

    .evSpeedBike {
        width: 100%;
        height: 100vh;
    }

    .trailerBtn {
        position: sticky;
        top: 400px;
        left: 70px;
        font-size: x-small;
    }

    .knowmore {
        position: sticky;
        top: 400px;
        left: 215px;
        font-size: x-small;

    }

    .varientsbtnContainer {
        margin-top: 150px;
    }

    .varientBtn {
        font-size: x-small;
    }

    .scooterDisplay {
        height: 350px;
    }

    .electricScooter {
        height: 350px;
    }


}

@media (max-width:376px) {

    .home {
        animation: fade 0.5s ease-in;
        height: 100vh;
        overflow-y: hidden;
        background: linear-gradient(180deg, rgb(211, 209, 209, 1), rgb(211, 209, 209, 1), #aba3a3);
    }

    .evSpeedBike {
        margin-top: 100px;
        width: auto;
        height: 341px;
        margin-left: -105px;
    }


    .trailerBtn {
        position: fixed;
        top: 460px;
        left: 60px;
        font-size: x-small;
    }

    .knowmore {
        position: fixed;
        top: 460px;
        left: 205px;
        font-size: x-small;

    }


}

@media (max-width:321px) {

    .trailerBtn {
        position: sticky;
        top: 400px;
        left: 30px;
        font-size: x-small;
    }

    .knowmore {
        position: sticky;
        top: 400px;
        left: 170px;
        font-size: x-small;

    }

    .evSpeedBike {
        margin-top: 100px;
        width: auto;
        height: 292px;
        margin-left: -91px;
    }

    .trailerBtn {
        position: fixed;
        top: 415px;
        left: 31px;
        font-size: x-small;
    }

    .knowmore {
        position: fixed;
        top: 415px;
        left: 175px;
        font-size: x-small;

    }

}