.about {
    font-family: Arial, Helvetica, sans-serif;
    animation: fade 0.5s ease-in;
}

.backgroundImg {
    opacity: 0.03;
    position: fixed;
    top: 180px;
    left: 250px;
    width: 60%;
    z-index: -1;
}

.aboutDetails {
    margin: auto 20px auto 20px;
    height: 60vh;
    overflow-y: scroll;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (max-width:1030px) {
    .backgroundImg {
        opacity: 0.03;
        position: fixed;
        top: 180px;
        left: 230px;
        width: 60%;
    }

    .aboutDetails {
        height: 60vh;
    }
}

@media (max-width:800px) {

    .backgroundImg {
        opacity: 0.03;
        position: fixed;
        top: 180px;
        left: 150px;
        width: 60%;
    }

    .aboutDetails {
        height: 60vh;
    }
}

@media (max-width:500px) {
    .backgroundImg {
        opacity: 0.03;
        position: fixed;
        top: 180px;
        left: 100px;
        width: 60%;
    }

    .aboutDetails {
        height: 60vh;
    }
}

@media (max-width:376px) {
    .backgroundImg {
        opacity: 0.03;
        position: fixed;
        top: 180px;
        left: 60px;
    }

    .aboutDetails {
        height: 60vh;
    }
}