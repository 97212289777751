.kitImg {
    width: 30%;
    padding: 10px;
    float: left;
}

.motorImg {
    padding: 10px;
    width: 30%;
    float: left;

}

.keyswitchImg {
    padding: 10px;
    width: 30%;
    float: left;

}

.holderImg {
    padding: 10px;
    width: 30%;
    float: left;

}

.controllerImg {
    padding: 10px;
    width: 30%;
    float: left;

}

.hybridkitContainer {
    height: 500px;
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    animation: 0.5s fade ease-in;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.imgContainer {
    float: left;
}

.hybridTitle {
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
}

@media (max-width:800px) {
    .hybridContainer {
        height: 400px;
    }

    .hybridTitle {
        font-size: small;
    }
}