.hybridContainerimg {
    width: 100%;
    height: 100vh;
    margin-top: -250px;
}

.hybridKit {
    position: sticky;
    top: 200px;
    left: 20%;
    background-color: rgba(0, 0, 0, 0.500);
    border-radius: 7px;
    padding: 30px;
    width: 700px;
    color: white;
    font-size: large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.hybridPageButton {
    position: sticky;
    top: 450px;
    left: 20%;
    padding: 10px 20px 10px 20px;
    background-color: rgba(255, 255, 255, 0.500);
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-decoration: none;
    border-radius: 7px;
}


@media (max-width:1030px) {

    .hybridContainerimg {
        width: 100%;
        height: 100vh;
    }

    .hybridKit {
        position: sticky;
        top: 200px;
        left: 13%;
    }

    .hybridPageButton {
        position: sticky;
        top: 450px;
        left: 13%;
    }
}

@media (max-width:800px) {

    .hybridContainerimg {
        width: 100%;
        height: 100vh;
    }

    .hybridKit {
        font-size: medium;
        position: sticky;
        width: 500px;
        top: 120px;
        left: 13%;
    }

    .hybridPageButton {
        font-size: medium;
        position: sticky;
        top: 350px;
        left: 13%;
    }
}

@media (max-width:426px) {

    .hybridContainerimg {
        width: 100%;
        height: 100vh;
    }

    .hybridKit {
        font-size: x-small;
        width: 280px;
        position: sticky;
        top: 120px;
        left: 10%;
    }

    .hybridPageButton {
        font-size: x-small;
        position: sticky;
        top: 300px;
        left: 10%;
    }

}

@media (max-width:376px) {

    .hybridContainerimg {
        width: 100%;
        height: 100vh;
    }

    .hybridKit {
        font-size: x-small;
        width: 250px;
        position: sticky;
        top: 110px;
        left: 8%;
    }

    .hybridPageButton {
        font-size: x-small;
        position: sticky;
        top: 310px;
        left: 8%;
    }

}

@media (max-width:321px) {

    .hybridContainerimg {
        width: 100%;
        height: 100vh;
    }

    .hybridKit {
        font-size: x-small;
        width: 200px;
        position: sticky;
        top: 120px;
        left: 10%;
    }

    .hybridPageButton {
        font-size: x-small;
        position: sticky;
        top: 350px;
        left: 10%;
    }

}