.polyHeader {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.polyContainer {
    font-family: Arial, Helvetica, sans-serif;
    margin: auto 20px auto 20px;
    width: 100%;
}

.polyImg {
    width: auto;
    height: 280px;
}

@media screen and (max-width:770px) {
    .polyImg {
        height: 200px;
        margin-left: 0%;
    }
}

@media screen and (max-width:450px) {

    .polyContainer{
        font-size: x-small;
    }

    .polyImg {
        width: 100%;
    }
}