.speedBikeimgContainer {
    animation: fade 1s ease-in;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}


@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.speedBikeimg {
    width: 100%;
    height: 100vh;
}

.features {
    position: fixed;
    top: 125px;
    left: 20px;
    background-color: rgba(255, 255, 255, 0.500);
    border-radius: 7px;
    animation: slideup 1s ease-in;
    padding: 15px;
}

@keyframes slideup {
    from {
        transform: translateY(200px);
        opacity: 0;

    }

    to {
        transform: translateY(0px);
        opacity: 1;

    }
}

.featureslist {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 10px;
}

.title {
    position: fixed;
    top: 80px;
    left: 20px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
    background-color: rgba(0, 0, 0, 0.200);
    border-radius: 7px;
    border: 2px solid skyblue;
    padding: 10px;
}

.subTitle {
    border: 1px solid yellow;
    padding: 5px;
    border-radius: 5px;
}

.sides {
    position: absolute;
    top: 525px;
    left: 20px;
}

.sidesLink {
    text-decoration: none;
    color: white;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: rgba(0, 0, 0, 0.500);
    padding: 6px 17px 6px 20px;
    margin-right: 10px;
    border-radius: 5px;
}

@media (max-width:1030px) {

    .features {
        margin-top: -10px;
    }

    .featureslist {
        font-size: x-small;
    }

    .title {
        font-size: x-small;
    }

    .subTitle {
        font-size: x-small;
    }

    .sides {
        position: absolute;
        top: 405px;
        left: 20px;
    }

    .sidesLink {
        font-size: x-small;
        padding: 5px 10px;
    }
}

@media (max-width:770px) {

    .title {
        margin-top: -15px;
        font-size: xx-small;
    }

    .featureslist {
        font-size: x-small;
    }

    .features {
        margin-top: -22px;
    }

    .speedBikeimg {
        width: 100%;
        height: 100vh;
    }

    .sides {
        position: absolute;
        top: 400px;
        left: 20px;
    }

    .sidesLink {
        font-size: xx-small;
    }
}

@media (max-width:480px) {

    .speedBikeimgContainer {
        height: 367px;
        width: auto;
        padding-top: 43px;
    }

    .speedBikeimg {
        padding-top: 43px;
        width: 132%;
    }

    .title {
        margin-top: 345px;
        margin-left: -13px;
    }

    .features {
        margin-top: -34px;
        width: 147px;
    }

    .sides {
        margin-top: -10px;
    }

    .sidesLink {
        margin-right: 5px;
    }

}

@media (max-width:376px) {

    .speedBikeimgContainer {
        height: 367px;
        width: 100%;
        padding-top: 43px;
    }

    .speedBikeimg {
        padding-top: 43px;
        width: 132%;
    }

    .features {
        margin-top: -35px;
    }

    .title {
        margin-top: 350px;
    }


}

@media (max-width:321px) {

    .title {
        width: 311px;
        margin: 355px auto auto -13px;
        padding: 0px;
        background-color: transparent;
        color: grey;
        border: none;
        padding-bottom: 7px;
        border-bottom: 1px solid gray;
    
    }

    .subTitle {
        border: none;
        color: skyblue;
    }
}