.cadHeader {
    font-size: larger;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.cadDetails {
    margin: 10px 20px 10px 20px;
}

.cadContainer{
    width: 100%;
}
