.servicesContainer {
    margin: 10px 20px 10px 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    animation: fade 0.5s ease-in;
    height: max-content;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.ourServices {
    text-align: center;
    font-size: larger;
    margin-right: 10px;
    color: gray;
}

.settingIcon {
    height: 25px;
    width: 25px;
    animation: tight 1s infinite;
}

@keyframes tight {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(45deg);
    }
}

.engServices {
    background-color: rgba(128, 128, 128, 0.080);
    text-align: center;
    height: 347px;
    margin: 10px auto auto 10px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.leftimg {
    float: left;
    height: 346px;
}

.rightimg {
    height: 347px;
    float: right;
}

.servicesLinksDiv {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.servicesLinks {
    text-decoration: none;
    margin: 10px auto auto 5px;
    color: gray;
    font-size: small;
}

.servicesLinks:hover {
    text-decoration: underline;
}

@media screen and (max-width:1025px) {

    .engServices {
        font-size: small;
        height: 300px;
    }

    .rightimg {
        height: 300px;
    }

    .leftimg {
        height: 300px;
    }

}

@media screen and (max-width:770px) {
    .ourServices {
        font-size: medium;
    }

    .leftimg {
        display: none;
    }

    .rightimg {
        display: none;
    }

    .engServices {
        font-size: small;
        padding: 10px;
    }

    .servicesLinks {
        font-size: small;
    }
}

@media (max-width:430px) {
    .engServices {
        height: max-content;
    }

    .settingIcon {
        display: none;
    }
}

@media (max-width:376px) {
    .servicesLinks {
        font-size: x-small;
    }

    .settingIcon {
        display: none;
    }
}

@media (max-width:321px) {

    .engServices {
        height: max-content;
    }

    .servicesLinks {
        font-size: x-small;
    }
}